import React from "react";
import { Route, Router, Redirect, Switch } from "react-router-dom";
import './App.css';
import history from "./services/history";
import HomeScreen from "./pages/HomeScreen";
import OurCompany from "./pages/OurCompany";
import Services from "./pages/Services";
import ContactUs from "./pages/ContactUs";
import ClientsAndPartners from "./pages/ClientsAndPartners";
import ComingSoon from "./pages/ComingSoon";


function App() {
  return (
    <Router history={history}>
      <div>
        <Switch>
          <Route exact path="/" component={ComingSoon} />
          {/* <Route exact path="/" component={HomeScreen} />
          <Route exact path="/our-company" component={OurCompany} />
          <Route exact path="/services/:type" component={Services} />
          <Route exact path="/contact-us" component={ContactUs} />
          <Route exact path="/clients-and-partners" component={ClientsAndPartners} /> */}
          <Route>
            <Redirect to="/" />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
