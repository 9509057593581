import { Container, Row, Col, Image } from "react-bootstrap";
import logo from '../assets/logo.png';
import './ComingSoon.css';

const ComingSoon = () => {
  return (
    <Container className={'parent-container center text-font'}>
      <Image src={logo} className={'image-size'} fluid />
      <Row className={'text-font'}>
        <h2>
          Coming Soon...
        </h2>
      </Row>
    </Container>
  )
}

export default ComingSoon;